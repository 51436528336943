var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',[_c('el-button',{staticClass:"mb20",attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.$router.push('/extension/crossWebsite/addShortVideo')}}},[_vm._v("+ 添加短视频")]),_c('el-form',{staticClass:"el-form-search",attrs:{"label-width":"110px"}},[_c('el-form-item',{attrs:{"label":"短视频标题："}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入短视频标题"},model:{value:(_vm.searchForm.nickname),callback:function ($$v) {_vm.$set(_vm.searchForm, "nickname", $$v)},expression:"searchForm.nickname"}})],1),_c('el-form-item',{attrs:{"label":"短视频分类："}},[_c('el-select',{attrs:{"size":"small"},model:{value:(_vm.searchForm.classify_id),callback:function ($$v) {_vm.$set(_vm.searchForm, "classify_id", $$v)},expression:"searchForm.classify_id"}},_vm._l((_vm.classify_list),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.classify_name,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"是否显示："}},[_c('el-select',{attrs:{"size":"small"},model:{value:(_vm.searchForm.is_show),callback:function ($$v) {_vm.$set(_vm.searchForm, "is_show", $$v)},expression:"searchForm.is_show"}},_vm._l((_vm.showList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.name,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":" ","label-width":"20px"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.getList}},[_vm._v("搜索")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":_vm.cancelSearch}},[_vm._v("清空搜索条件")])],1)],1),_c('el-table',{attrs:{"data":_vm.dataList,"header-cell-style":{ 'background-color': '#F8F9FA' }}},[_c('template',{slot:"empty"},[_c('No')],1),_c('el-table-column',{attrs:{"label":"排序","align":"center","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"size":"small","type":"number","min":0},on:{"blur":function($event){return _vm.updateVideoItem(_vm.item)}},model:{value:(row.sort),callback:function ($$v) {_vm.$set(row, "sort", $$v)},expression:"row.sort"}})]}}])}),_c('el-table-column',{attrs:{"label":"短视频封面","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"75px","height":"40px"},attrs:{"src":row.cover_picture,"preview-src-list":[row.cover_picture]}})]}}])}),_c('el-table-column',{attrs:{"prop":"title","label":"短视频标题","align":"center"}}),_c('el-table-column',{attrs:{"prop":"classify_name","label":"短视频分类","align":"center"}}),_c('el-table-column',{attrs:{"prop":"source","label":"短视频来源","align":"center"}}),_c('el-table-column',{attrs:{"label":"短视频二维码","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"75px","height":"40px"},attrs:{"src":row.qrcode,"preview-src-list":[row.qrcode]}})]}}])}),_c('el-table-column',{attrs:{"prop":"true_view","label":"真实查看数量","align":"center"}}),_c('el-table-column',{attrs:{"prop":"true_like","label":"真实点赞数量","align":"center"}}),_c('el-table-column',{attrs:{"label":"显示","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"active-value":1,"inactive-value":0},on:{"change":function($event){return _vm.updateVideoItem(row)}},model:{value:(row.is_show),callback:function ($$v) {_vm.$set(row, "is_show", $$v)},expression:"row.is_show"}})]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.toEditVideo(row)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteVideo(row)}}},[_vm._v("删除")])]}}])})],2),_c('Paging',{attrs:{"total":_vm.total,"page":_vm.page,"pageNum":_vm.rows},on:{"updatePageNum":_vm.updateData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }